import React, { useState, useEffect, useCallback } from 'react';
import './Footer.css';
import { useBackground } from '../contexts/BackgroundContext';

function Footer({ isContactPage }) {
  const [time, setTime] = useState('');
  const { toggleBackground, isGradientBackground } = useBackground();

  const updateTime = useCallback(() => {
    const date = new Date();
    const options = {
      timeZone: 'America/Los_Angeles',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    return date.toLocaleTimeString('en-US', options);
  }, []);

  useEffect(() => {
    let animationFrameId;
    let lastUpdateSecond = -1;

    const updateTimeAndSchedule = () => {
      const newTime = updateTime();
      const currentSecond = new Date().getSeconds();

      if (currentSecond !== lastUpdateSecond) {
        setTime(newTime);
        lastUpdateSecond = currentSecond;
      }

      animationFrameId = requestAnimationFrame(updateTimeAndSchedule);
    };

    updateTimeAndSchedule();

    return () => cancelAnimationFrame(animationFrameId);
  }, [updateTime]);

  const handleBackgroundToggle = () => {
    toggleBackground();
  };

  return (
    <footer className={`footer ${isContactPage ? 'contact-footer' : ''}`}>
      <div className="location">
        <span className="time">{time}</span>
        <span className="divider"></span>
        <span className="place">los angeles, california</span>
      </div>
      <div className="switch-container">
        <label className="switch">
          <input 
            type="checkbox" 
            checked={isGradientBackground} 
            onChange={handleBackgroundToggle}
          />
          <span className="slider round"></span>
        </label>
      </div>
      <p className="copyright">
        Ojas Nimase © {new Date().getFullYear()}. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
