import React, { useEffect, useState, lazy, Suspense } from 'react';
import './Blog.css';

const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('./Footer'));
const FaFlask = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaFlask })));
const FaTools = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaTools })));
const FaCode = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaCode })));
const FaPaintBrush = lazy(() => import('react-icons/fa').then(module => ({ default: module.FaPaintBrush })));

function Blog() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const blogPosts = [
    { 
      title: 'Rebuilding My Site', 
      tags: ['Technical', 'Design'],
      link: 'https://yellow-moustache-def.notion.site/Rebuilding-My-Site-11516e67a8718086a2b7f210e371bbcf?pvs=4'
    },
    /*{ 
      title: 'Assistive Aquaponics', 
      tags: ['Research', 'Hardware'],
      link: 'https://example.com/assistive-aquaponics'
    },*/
  ];

  const getTagIcon = (tag) => {
    switch (tag.toLowerCase()) {
      case 'technical':
        return <Suspense fallback={<span>Loading...</span>}><FaCode /></Suspense>;
      case 'design':
        return <Suspense fallback={<span>Loading...</span>}><FaPaintBrush /></Suspense>;
      case 'research':
        return <Suspense fallback={<span>Loading...</span>}><FaFlask /></Suspense>;
      case 'hardware':
        return <Suspense fallback={<span>Loading...</span>}><FaTools /></Suspense>;
      default:
        return null;
    }
  };

  return (
    <div className="blog">
      <Suspense>
      <Header isMobile={isMobile} />
      </Suspense>
      <main className="content">
        <div className="intro">
          <p>Some of my writings (technical, commentary, etc). </p>
        </div>
        <div className="three-column-layout">
          <div className="column"></div>
          <div className="column">
            <div className="blog-bento-box">
              {blogPosts.map((post, index) => (
                <a 
                  key={index} 
                  className="blog-post-box" 
                  href={post.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="blog-post-content">
                    <div className="tags">
                      {post.tags.map((tag, tagIndex) => (
                        <span key={tagIndex} className="tag">
                          {getTagIcon(tag)}
                          {tag}
                        </span>
                      ))}
                    </div>
                    <h2>{post.title}</h2>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="column"></div>
        </div>
      </main>
      <Suspense>
        <Footer />
      </Suspense>
    </div>
  );
}

export default Blog;
