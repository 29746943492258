import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header({ isMobile }) {
  const navItems = ['about', 'writing', 'contact'];

  const renderNavItem = (item, index) => {
    if (item === 'about') {
      return (
        <a 
          key={index}
          href="https://ojasnimase.com"
          className={`nav-item one-${index + 1}`}
        >
          {item}
        </a>
      );
    } else {
      return (
        <Link 
          key={index}
          to={`/${item}`}
          className={`nav-item one-${index + 1}`}
        >
          {item}
        </Link>
      );
    }
  };

  return (
    <header className="header">
      <nav className="nav-list">
        {isMobile ? (
          <>
            <div className="upper-nav">
              {navItems.map((item, index) => renderNavItem(item, index))}
            </div>
            <div className="divider"></div>
          </>
        ) : (
          <>
            {navItems.map((item, index) => renderNavItem(item, index))}
            <div className="divider"></div>
          </>
        )}
      </nav>
    </header>
  );
}

export default Header;