import React, { createContext, useState, useContext, useCallback } from 'react';

const BackgroundContext = createContext();

export const BackgroundProvider = ({ children }) => {
  const [isGradientBackground, setIsGradientBackground] = useState(() => {
    const saved = localStorage.getItem('isGradientBackground');
    return saved !== null ? JSON.parse(saved) : false;
  });

  const toggleBackground = useCallback(() => {
    setIsGradientBackground(prev => {
      const newValue = !prev;
      localStorage.setItem('isGradientBackground', JSON.stringify(newValue));
      return newValue;
    });
  }, []);

  return (
    <BackgroundContext.Provider value={{ isGradientBackground, toggleBackground }}>
      {children}
    </BackgroundContext.Provider>
  );
};

export const useBackground = () => useContext(BackgroundContext);