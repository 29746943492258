import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { BackgroundProvider } from './contexts/BackgroundContext';
import BackgroundManager from './components/BackgroundManager';
import HomePage from './Views/HomePage';
import Blog from './Views/Blog';
import Contact from './Views/Contact';
import NotFound from './components/NotFound';

function App() {
  return (
    <BackgroundProvider>
      <Router>
        <BackgroundManager />
        <Suspense fallback={<div style={{height: '100vh', backgroundColor: 'black'}}></div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/writing" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </Router>
    </BackgroundProvider>
  );
}

export default App;