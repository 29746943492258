import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      backgroundColor: 'black',
      color: 'white',
      fontFamily: 'Inter, sans-serif'
    }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, that page doesn't exist!</p>
      <Link to="/" style={{ color: '#0ef0b8', textDecoration: 'none' }}>
        Click here to go to the homepage
      </Link>
    </div>
  );
}

export default NotFound;
