import React, { useEffect, useRef } from 'react';
import { useBackground } from '../contexts/BackgroundContext';
import { Gradient } from './Gradient';

function BackgroundManager() {
  const { isGradientBackground } = useBackground();
  const canvasRef = useRef(null);

  useEffect(() => {
    if (isGradientBackground && canvasRef.current) {
      const gradient = new Gradient();
      gradient.initGradient('#gradient-canvas');
    }
  }, [isGradientBackground]);

  return (
    <>
      {isGradientBackground && (
        <canvas 
          ref={canvasRef} 
          id="gradient-canvas" 
          data-transition-in 
        />
      )}
      {!isGradientBackground && (
        <div className="black-background" />
      )}
    </>
  );
}

export default BackgroundManager;